import { Combobox, MantineStyleProp } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { GroupSelectItem, SelectsDataType } from '../types/select/common';
import DropdownOption from './dropdownOption';

interface DropdownProps {
    data: SelectsDataType[];
    value: string | string[];
    width: number | string;
    checkIcon?: boolean;
    gradientBG?: boolean;
    rightIcon?: JSX.Element;
    formattingFunction?: (str: string) => string;
    searchValue?: string;
    creatable?: boolean;
    getCreateLabel?: (query: string) => string;
    onCreate?: (query: string) => string;
    limit?: number;
    dropdownClassName?: string;
    dropdownStyle?: MantineStyleProp;
    nothingFoundMessage?: string;
}

function Dropdown({
    data,
    value,
    width,
    checkIcon,
    rightIcon,
    searchValue,
    formattingFunction,
    gradientBG,
    getCreateLabel,
    onCreate,
    creatable,
    limit,
    dropdownClassName,
    dropdownStyle,
    nothingFoundMessage,
}: DropdownProps) {
    const test = useCallback(
        (val: string) => {
            if (!searchValue) return true;
            return val
                .toLowerCase()
                .trimEnd()
                .trimStart()
                .includes(searchValue.toLowerCase().trimEnd().trimStart());
        },
        [searchValue],
    );

    const filteredData = useMemo(
        () =>
            (typeof searchValue === 'string' &&
                data.reduce((acc: SelectsDataType[], item) => {
                    if (typeof item === 'string') {
                        if (test(item)) return [...acc, item];
                        return acc;
                    }
                    if ('value' in item && test(item.label)) return [...acc, item];
                    if ('group' in item) {
                        return [
                            ...acc,
                            {
                                ...item,
                                items: item.items.filter(v =>
                                    typeof v === 'string' ? test(v) : test(v.label),
                                ),
                            },
                        ];
                    }
                    return acc;
                }, [])) ||
            data,
        [data, searchValue, test],
    );

    const displayData = useMemo(() => {
        const sample = filteredData?.[0];
        if (!sample?.['group']) {
            return filteredData.slice(0, limit);
        }
        const groupLimit = Math.floor((limit - filteredData.length) / filteredData.length);
        return filteredData.map((item: GroupSelectItem) => ({
            ...item,
            items: item.items.slice(0, groupLimit),
        }));
    }, [filteredData, limit]);

    const options = useMemo(() => {
        if (displayData.length === 0) {
            if (nothingFoundMessage) {
                return (
                    <p className="px-1 py-2.5 text-center text-sm text-navy-solid-70">
                        {nothingFoundMessage}
                    </p>
                );
            }

            return null;
        }

        return displayData.map(item => (
            <DropdownOption
                key={`dropdown_${item?.['group'] ?? item?.['label'] ?? item}`}
                option={item}
                checkIcon={checkIcon}
                value={value}
                formattingFunction={formattingFunction}
                rightIcon={rightIcon}
            />
        ));
    }, [displayData, checkIcon, value, formattingFunction, rightIcon, nothingFoundMessage]);

    if ((options === null || data.length === 0) && !creatable) {
        return null;
    }

    return (
        <Combobox.Dropdown
            w={width}
            style={dropdownStyle}
            styles={{
                dropdown: {
                    minWidth: width,
                    maxWidth: width,
                },
            }}
            className={classNames(
                'border-r-0 border-b-0 p-2 max-h-[216px] min-h-10 overflow-auto min-w-fit w-full transition-all',
                dropdownClassName,
            )}
            classNames={{
                dropdown: classNames('!z-[9999]', {
                    'gradient-bg-hex': gradientBG,
                    '!bg-secondary-5 glass-effect': !gradientBG,
                }),
            }}
        >
            <Combobox.Options classNames={{ options: 'w-full' }}>{options}</Combobox.Options>
            {creatable && searchValue && searchValue?.length > 0 && (
                <DropdownOption
                    option={{
                        value: searchValue,
                        label: getCreateLabel?.(searchValue) ?? searchValue,
                    }}
                    checkIcon={checkIcon}
                    value={value}
                    formattingFunction={formattingFunction}
                    rightIcon={rightIcon}
                    onCreate={() =>
                        onCreate?.(searchValue) ?? {
                            value: searchValue,
                            label: getCreateLabel?.(searchValue) ?? searchValue,
                        }
                    }
                />
            )}
        </Combobox.Dropdown>
    );
}

export default Dropdown;

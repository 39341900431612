import { Combobox } from '@mantine/core';
import { Check } from 'phosphor-react';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import StyledTooltip from '../../components/utils/styledTooltip';
import { SelectItem, SelectsDataType } from '../types/select/common';

function DropdownOption({
    option,
    checkIcon,
    value,
    onCreate,
    rightIcon,
    formattingFunction,
}: {
    option: SelectsDataType;
    checkIcon?: boolean;
    value: string | string[];
    rightIcon?: JSX.Element;
    formattingFunction?: (str: string) => string;
    onCreate?: () => void;
}) {
    const getOption = useCallback(
        (item: string | SelectItem) => {
            if (typeof item === 'string') {
                return (
                    <Combobox.Option
                        value={item}
                        className={classNames(
                            'flex items-center font-medium gap-2 w-full my-1 !text-navy-solid-50 hover:!bg-risd-blue-10',
                            {
                                '!border border-border-color p-0.5': typeof onCreate === 'function',
                                '!text-navy-solid-50': typeof value === 'string' && value !== item,
                                '!text-navy-solid-70':
                                    typeof value === 'string' &&
                                    value === item &&
                                    value?.includes(item),
                                '!bg-risd-blue-10':
                                    !checkIcon &&
                                    (typeof value === 'string'
                                        ? value === item
                                        : value?.includes(item)),
                            },
                        )}
                        onClick={onCreate}
                    >
                        {typeof onCreate !== 'function' &&
                            (checkIcon &&
                            (typeof value === 'string' ? value === item : value?.includes(item)) ? (
                                <Check size={16} />
                            ) : (
                                <div className={checkIcon ? 'w-4' : ''} />
                            ))}
                        {formattingFunction?.(item) ?? item}
                    </Combobox.Option>
                );
            }

            return (
                <StyledTooltip
                    label={item?.tooltipLabel}
                    disabled={!item?.tooltipLabel}
                    position="right"
                    withinPortal
                    withArrow
                    className="z-[9999999] mx-1"
                    w={200}
                >
                    <Combobox.Option
                        value={item.value}
                        className={classNames(
                            'flex items-center font-medium gap-2 my-1 text-sm w-full !text-navy-solid-50 hover:!bg-risd-blue-10',
                            {
                                '!border border-border-color p-0.5': typeof onCreate === 'function',
                                '!text-navy-solid-50':
                                    typeof value === 'string' && value !== item.value,
                                '!text-navy-solid-70':
                                    typeof value === 'string' &&
                                    value === item.value &&
                                    value?.includes(item.value),
                                '!bg-risd-blue-10':
                                    !checkIcon &&
                                    (typeof value === 'string'
                                        ? value === item.value
                                        : value?.includes(item.value)),
                            },
                        )}
                        disabled={item?.disabled}
                        onClick={onCreate}
                    >
                        {typeof onCreate !== 'function' &&
                            (checkIcon &&
                            (typeof value === 'string'
                                ? value === item.value
                                : value?.includes(item.value)) ? (
                                <Check size={16} />
                            ) : (
                                (item?.icon && item.icon) || (
                                    <div className={checkIcon || item?.icon ? 'w-4' : ''} />
                                )
                            ))}
                        {formattingFunction?.(item.label) ?? item.label}
                        {(item?.rightIcon ?? rightIcon) && (
                            <div className="self-end"> {item?.rightIcon ?? rightIcon}</div>
                        )}
                    </Combobox.Option>
                </StyledTooltip>
            );
        },
        [checkIcon, formattingFunction, onCreate, rightIcon, value],
    );

    if (!option) return null;

    if (typeof option !== 'string' && 'group' in option)
        return (
            <div className="w-fit min-w-full">
                <div className="flex w-full items-center gap-1 whitespace-nowrap py-1 text-xs capitalize text-navy-solid-30">
                    {option.group}{' '}
                    <div className="h-0 w-full rounded border-0.5 border-border-color" />
                </div>
                {option.items.map(item => getOption(item))}
            </div>
        );
    return getOption(option);
}

export default DropdownOption;

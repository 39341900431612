const htmlEntityNamesBank = {
    '&amp;': '&',
};

const replaceHtmlEntityNames = (str: string) => {
    if (!str) return '';
    let formattedString = str;

    Object.entries(htmlEntityNamesBank).forEach(([entity, val]) => {
        if (formattedString.includes(entity)) formattedString = str.replaceAll(entity, val);
    });
    return formattedString;
};

export { replaceHtmlEntityNames };

import { DatePickerValue } from '@mantine/dates';
import moment from 'moment';

const fusionMoment = (str?: string | Date) => moment(str ?? new Date(), true).utc(true);

const productMetricsQueryServiceDateFormate = 'YYYY-MM-DD';

const dateSubtraction = (num: number, duration: moment.unitOfTime.DurationConstructor) =>
    // ? First subtract is to remove today
    fusionMoment()
        .subtract(1, 'd')
        .subtract(num, duration)
        .format(productMetricsQueryServiceDateFormate);

const dateSubtractionMonthly = (num: number, duration: moment.unitOfTime.DurationConstructor) =>
    fusionMoment()
        .endOf('month')
        .subtract(num, duration)
        .format(productMetricsQueryServiceDateFormate);

const endOfMonth = (val?: Date | string) =>
    fusionMoment(val).endOf('month').format(productMetricsQueryServiceDateFormate);

const dateFormate = (val?: Date | string) =>
    fusionMoment(val).format(productMetricsQueryServiceDateFormate);

const csvDateFormat = (str?: string | Date) => fusionMoment(str).format('MMM DD,YYYY');

const formateDateRangeValueForDisplay = (arr: DatePickerValue<'range'>) =>
    fusionMoment(arr[0]).isSame(fusionMoment(arr[1]))
        ? csvDateFormat(arr[0])
        : arr.map(date => (!date ? '  Select Date  ' : csvDateFormat(date))).join(' → ');

const chartDateFormatter = (str: string) => fusionMoment(str).format('ddd DD MMM yyyy');
const bannerAssortmentChartDateFormatter = (str: string, extra?: string) =>
    fusionMoment(str).format(`DD MMM yyyy${extra || ''}`);

const generateNewDates = (n?: number, d?: 'm') => {
    if (d === 'm') {
        dateSubtraction(6, 'days');
        return dateSubtraction(Number(n), 'M');
    }
    return dateSubtraction(6, 'days');
};
const dateHelper = (date: [string, string]): [string, string] => {
    const a = fusionMoment(date[1]);
    const b = fusionMoment(date[0]);
    const diffMonths = a.diff(b, 'M');
    const diffDays = a.diff(b, 'days');
    let newMaxDate = date[1];
    let newMinDate = date[0];
    if (diffMonths === 3 || diffMonths === 6 || diffMonths === 1 || diffMonths === 12) {
        newMaxDate = dateFormate(undefined);
        newMinDate = generateNewDates(diffMonths, 'm');
    } else if (diffDays === 6) {
        newMaxDate = dateFormate(undefined);
        newMinDate = generateNewDates();
    }
    return [newMinDate, newMaxDate];
};

export {
    fusionMoment,
    csvDateFormat,
    bannerAssortmentChartDateFormatter,
    chartDateFormatter,
    dateSubtraction,
    dateFormate,
    productMetricsQueryServiceDateFormate,
    formateDateRangeValueForDisplay,
    dateHelper,
    dateSubtractionMonthly,
    endOfMonth,
};

import { replaceHtmlEntityNames } from './replaceHtmlEntityNames';
import { lettersDB, searchReg } from './lettersDB';
import { FusionSelectItem, SelectItem, SelectsDataType } from '../types/select/common';

const reshapingData = (
    arr: FusionSelectItem[],
    itemsFormattingFunction?: (str: string, key?: string) => string,
    key?: string,
) =>
    arr?.map(item => {
        if (typeof item === 'string') {
            const res = lettersDB.filter(char => item.match(char.letters));
            let str = item
                .toLowerCase()
                .replace(searchReg, '')
                .replace(/(\s){2,}/g, ' ');
            res.forEach(obj => {
                str = str.replace(obj.letters, obj.base);
            });
            const cleanStr = itemsFormattingFunction?.(item, key) ?? replaceHtmlEntityNames(str);
            return { value: item, label: cleanStr };
        }
        return {
            ...item,
            value: item.value,
            label: itemsFormattingFunction?.(item.label) ?? item.label,
        };
    });

const filterData = (val: string, arr: SelectItem[]) => {
    if (!val || val.length === 0) return arr;
    const valSterile = val.toLowerCase().trim();
    const res = !/^[\w\d\s]+$/g.test(val.trim())
        ? arr.filter(item => item.value.toLowerCase().includes(valSterile))
        : arr.filter(item => item.label.toLowerCase().includes(valSterile.replace(searchReg, '')));
    return res;
};

const getKeys = (data: SelectsDataType[]): Record<string, SelectItem> =>
    data.reduce((acc, item) => {
        if (item === null) return acc;
        if (typeof item !== 'string' && 'group' in item && 'value' in item) {
            throw new Error(
                'The Correct format is {group: <GROUP_NAME>; items: (string|SelectItem)[]',
            );
        }
        if (typeof item !== 'string' && 'value' in item) Object.assign(acc, { [item.value]: item });
        if (typeof item !== 'string' && 'group' in item) {
            if (typeof item.items?.[0] === 'string') return acc;
            item.items.forEach(
                gItem => typeof gItem !== 'string' && Object.assign(acc, { [gItem.value]: gItem }),
            );
        }
        return acc;
    }, {});

export { reshapingData, filterData, getKeys };

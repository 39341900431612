import { Title } from '@mantine/core';
import classNames from 'classnames';
import { Calendar, CaretDown, CaretRight } from 'phosphor-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DatePickerInput, DatePickerValue, MonthPickerInput } from '@mantine/dates';
import {
    dateFormate,
    dateSubtraction,
    endOfMonth,
    formateDateRangeValueForDisplay,
    fusionMoment,
} from '../utils/dateHelpers';
import { primaryColors } from '../constants/colors';
import { durations as dailyDuration, monthlyDurations } from '../constants/pricingIntelligence';

const minPossibleDate = new Date('2021/10/15');
const dateRangeStringVal = 'date range';

function DatePicker({
    minDate,
    maxDate,
    handleChange,
    id,
    isVladDesign,
    isMonthSelector,
    selectedValueClassName,
    dropdownClassName,
    disableDateRange,
    singleDate,
}: {
    minDate: string;
    maxDate: string;
    handleChange: (startDate: string, endDate?: string) => void;
    id?: string;
    isVladDesign?: boolean;
    isMonthSelector?: boolean;
    selectedValueClassName?: string;
    dropdownClassName?: string;
    disableDateRange?: boolean;
    singleDate?: boolean;
}) {
    const [value, setValue] = useState(minDate);
    const [isOpen, setIsOpen] = useState(false);
    const [maxDateDefault] = useState(isMonthSelector ? endOfMonth() : dateSubtraction(0, 'd'));
    const [highlighted, setHighlighted] = useState<number | undefined>(0);
    const [isDateRange, setIsDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState<DatePickerValue<'range'>>([null, null]);
    const [dateRangeSingleValue, setDateRangeSingleValue] = useState<DatePickerValue<'default'>>(
        new Date(minDate),
    );
    const [mouseIsInside, setMouseIsInside] = useState(false);
    const durations = isMonthSelector ? monthlyDurations : dailyDuration;

    const isMaxDateToday = maxDate === maxDateDefault;
    const selectedDuration = useMemo<(typeof durations)[0] | undefined>(
        () => (!isMaxDateToday ? undefined : durations.filter(obj => obj.value === minDate)?.[0]),
        [durations, isMaxDateToday, minDate],
    );

    const handleClick = (min: string, max: string) => {
        setValue(min);
        setDateRangeValue([null, null]);
        setIsOpen(false);
        handleChange(min, max);
    };
    const refs = durations
        .concat({ label: dateRangeStringVal, value: dateRangeStringVal })
        .map(() => React.createRef<HTMLButtonElement>());

    const index =
        value === 'date range'
            ? durations.length
            : durations.findIndex(item => item.value === value);

    useEffect(() => {
        if (Number(highlighted) >= 0 || !isOpen || !durations.length) return;
        setHighlighted(index);
        refs[index]?.current?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [durations, highlighted, index, isOpen, refs]);

    useEffect(() => {
        if (selectedDuration?.label && isMaxDateToday) {
            setValue(minDate);
            setDateRangeValue([null, null]);
        } else if (!selectedDuration?.label && !isMaxDateToday) {
            setValue(dateRangeStringVal);
            setDateRangeValue([
                fusionMoment(minDate).toDate(),
                isMonthSelector
                    ? fusionMoment(maxDate).endOf('month').toDate()
                    : fusionMoment(maxDate).toDate(),
            ]);
        }
    }, [isMaxDateToday, isMonthSelector, maxDate, minDate, selectedDuration?.label]);

    useEffect(() => {
        if (isOpen) return;
        setHighlighted(undefined);
    }, [isOpen]);

    const handleKeys = (e: React.KeyboardEvent, val: string) => {
        if (isDateRange) return;
        if (
            e.key === 'ArrowDown' ||
            e.key === 'ArrowUp' ||
            e.key === 'Enter' ||
            e.key === 'Escape'
        ) {
            e.preventDefault();
        }
        if (e.key === 'ArrowUp' && Number(highlighted) > 0) {
            setHighlighted(curr => Number(curr) - 1);
        } else if (e.key === 'ArrowDown' && Number(highlighted) < refs.length - 1) {
            setHighlighted(curr => Number(curr) + 1);
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        } else if (e.key === 'Enter') {
            if (val === dateRangeStringVal) {
                setIsOpen(false);
                setIsDateRange(true);
                setValue(dateRangeStringVal);
            } else handleClick(val, maxDateDefault);
        }
    };

    const handleDatePickerChange = useCallback(
        (e: DatePickerValue<'range'>) => {
            setValue(dateRangeStringVal);
            if (!e.includes(null)) {
                handleChange(dateFormate(e[0]), dateFormate(e[1]));
            }
            setDateRangeValue(() => e);
        },
        [handleChange],
    );

    const renderSelectedDateOrDuration = useMemo(
        () =>
            singleDate
                ? formateDateRangeValueForDisplay([
                      new Date(dateRangeSingleValue),
                      new Date(dateRangeSingleValue),
                  ])
                : (dateRangeValue[0] === null &&
                      (selectedDuration?.label ||
                          formateDateRangeValueForDisplay([
                              new Date(minDate),
                              new Date(maxDate),
                          ]))) ||
                  formateDateRangeValueForDisplay(dateRangeValue),
        [
            dateRangeSingleValue,
            dateRangeValue,
            maxDate,
            minDate,
            selectedDuration?.label,
            singleDate,
        ],
    );
    return (
        <div
            className="relative w-fit"
            onBlur={e => {
                if (!e.relatedTarget || (e.relatedTarget && !mouseIsInside)) {
                    setIsOpen(false);
                }
            }}
            onMouseEnter={() => {
                setMouseIsInside(true);
            }}
            onMouseLeave={() => {
                setMouseIsInside(false);
            }}
        >
            <button
                className={classNames(selectedValueClassName, {
                    ' h-10 z-10 rounded flex justify-between p-3 bg-secondary-5 text-navy-solid-50 items-center min-w-[224px] max-w-[254px] border border-border-color':
                        !isVladDesign,
                    'h-10 z-10 flex p-3 bg-transparent text-navy-solid-50 items-center min-w-[224px] max-w-[254px] gap-1':
                        isVladDesign,
                })}
                type="button"
                onClick={() => {
                    if (singleDate) setIsDateRange(true);
                    setIsOpen(true);
                }}
            >
                {isVladDesign ? (
                    <>
                        <Calendar size={24} color={primaryColors.blue} />
                        <Title order={5}>{renderSelectedDateOrDuration}</Title>
                    </>
                ) : (
                    <>
                        <Title order={5}>{renderSelectedDateOrDuration}</Title>
                        <CaretDown size={14} />
                    </>
                )}
            </button>
            {(singleDate && (
                <DatePickerInput
                    type="default"
                    id="prod-search-picker-date"
                    color="#3D71F9"
                    dropdownType="popover"
                    popoverProps={{
                        opened: isOpen,
                        onClose: () => {
                            setIsDateRange(false);
                            if (dateRangeValue.includes(null) && value === 'date range') {
                                setValue(String(selectedDuration?.value));
                            }
                        },
                        classNames: {
                            dropdown: 'absolute',
                        },
                    }}
                    value={dateRangeSingleValue}
                    onChange={e => {
                        handleDatePickerChange([e, e]);
                        setDateRangeSingleValue(e);
                        setIsOpen(false);
                    }}
                    maxDate={fusionMoment().subtract(1, 'd').toDate()}
                    minDate={fusionMoment(minPossibleDate).toDate()}
                    onKeyDownCapture={e => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    classNames={{
                        day: 'data-[selected=true]:!bg-risd-blue data-[selected=true]:!text-navy-solid-30 data-[in-range=true]:!text-navy-solid-30 data-[in-range=true]:bg-[#3D71F955] !text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                        input: '-z-10 !border-transparent !bg-transparent !p-0 relative !m-0 !w-0 !h-0 !max-h-0 !text-transparent',
                        root: '-mt-[38px] relative !bg-transparent  -z-10',
                        yearsListControl:
                            '!text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent rounded',
                        monthsListControl:
                            '!text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent rounded',
                        calendarHeaderControlIcon:
                            '!text-navy-solid-70 disabled:!text-navy-solid-15',
                        calendarHeaderControl:
                            'hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                        calendarHeaderLevel:
                            'hover:bg-primary-20 !text-navy-solid-70 data-[disabled=true]:!bg-transparent',
                    }}
                    locale="en"
                    closeOnChange
                />
            )) ||
                (isDateRange && (
                    <div className="relative">
                        {isMonthSelector ? (
                            <MonthPickerInput
                                type="range"
                                id="prod-search-picker-date"
                                color="#3D71F9"
                                dropdownType="popover"
                                popoverProps={{
                                    opened: isDateRange,
                                    onClose: () => {
                                        setIsDateRange(false);
                                        if (
                                            dateRangeValue.includes(null) &&
                                            value === 'date range'
                                        ) {
                                            setValue(String(selectedDuration?.value));
                                        }
                                    },
                                    classNames: {
                                        dropdown: 'absolute',
                                    },
                                }}
                                allowSingleDateInRange
                                value={dateRangeValue}
                                onChange={handleDatePickerChange}
                                maxDate={fusionMoment().subtract(1, 'd').toDate()}
                                minDate={fusionMoment(minPossibleDate).toDate()}
                                onKeyDownCapture={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                classNames={{
                                    input: '-z-10 relative !h-0 !text-transparent !bg-transparent',
                                    root: '-mt-[38px]',
                                    yearsListControl:
                                        '!text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent rounded',
                                    monthsListControl:
                                        'data-[selected=true]:!bg-risd-blue data-[selected=true]:!text-navy-solid-30 data-[in-range=true]:!text-navy-solid-30 data-[in-range=true]:bg-[#3D71F955] !text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                                    calendarHeaderControlIcon:
                                        '!text-navy-solid-70 disabled:!text-navy-solid-15',
                                    calendarHeaderControl:
                                        'hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                                    calendarHeaderLevel:
                                        'hover:bg-primary-20 !text-navy-solid-70 data-[disabled=true]:!bg-transparent',
                                }}
                                locale="en"
                                closeOnChange
                            />
                        ) : (
                            <DatePickerInput
                                type={singleDate ? 'default' : 'range'}
                                id="prod-search-picker-date"
                                color="#3D71F9"
                                dropdownType="popover"
                                popoverProps={{
                                    opened: isDateRange,
                                    onClose: () => {
                                        setIsDateRange(false);
                                        if (
                                            dateRangeValue.includes(null) &&
                                            value === 'date range'
                                        ) {
                                            setValue(String(selectedDuration?.value));
                                        }
                                    },
                                    classNames: {
                                        dropdown: 'absolute',
                                    },
                                }}
                                allowSingleDateInRange
                                value={singleDate ? dateRangeValue?.[0] : dateRangeValue}
                                onChange={handleDatePickerChange}
                                maxDate={fusionMoment().subtract(1, 'd').toDate()}
                                minDate={fusionMoment(minPossibleDate).toDate()}
                                onKeyDownCapture={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                classNames={{
                                    day: 'data-[selected=true]:!bg-risd-blue data-[selected=true]:!text-navy-solid-30 data-[in-range=true]:!text-navy-solid-30 data-[in-range=true]:bg-[#3D71F955] !text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                                    input: '-z-10 relative !text-transparent',
                                    root: '-mt-[38px]',
                                    yearsListControl:
                                        '!text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent rounded',
                                    monthsListControl:
                                        '!text-navy-solid-70 disabled:!text-navy-solid-15 hover:bg-primary-20 data-[disabled=true]:!bg-transparent rounded',
                                    calendarHeaderControlIcon:
                                        '!text-navy-solid-70 disabled:!text-navy-solid-15',
                                    calendarHeaderControl:
                                        'hover:bg-primary-20 data-[disabled=true]:!bg-transparent',
                                    calendarHeaderLevel:
                                        'hover:bg-primary-20 !text-navy-solid-70 data-[disabled=true]:!bg-transparent',
                                }}
                                locale="en"
                                closeOnChange
                            />
                        )}
                    </div>
                ))}
            {!singleDate && isOpen && (
                <div
                    className={classNames(
                        'absolute z-[300] bg-secondary-5 glass-effect flex flex-col justify-start items-center border rounded border-border-color w-56 mt-1 p-2 text-navy-solid-50',
                        {
                            'border-r-0 border-b-0': isVladDesign,
                        },
                        dropdownClassName,
                    )}
                    // ? Here the useCase is different from Large Data select. Having the onKey on btns doesnt work.
                    // ? Issue: the function gets locked on the Selected Duration values.
                    // ? IE 30 days [selected] when hit Enter on 7 Days, we get the 30 days item in the handle click fn
                    onKeyDownCapture={e =>
                        handleKeys(e, durations?.[Number(highlighted)]?.value ?? 'date range')
                    }
                >
                    {durations.map((item, i) => (
                        <button
                            key={item.label}
                            ref={refs[i]}
                            type="button"
                            className={classNames(
                                'w-full flex justify-start items-center gap-1 p-1.5 rounded hover:bg-risd-blue-10 text-sm',
                            )}
                            onClick={() => {
                                handleClick(item.value, maxDateDefault);
                            }}
                        >
                            <div className="w-4">{item.value === value ? '✓' : ''}</div>
                            {item.label}
                        </button>
                    ))}
                    {!disableDateRange && (
                        <button
                            type="button"
                            key="date range"
                            ref={refs[durations.length]}
                            className={classNames(
                                'w-full flex justify-start items-center gap-2 p-1.5 rounded hover:bg-risd-blue-10',
                                {
                                    'border-t border-border-color border-dashed': isVladDesign,
                                },
                            )}
                            onClick={() => {
                                setIsOpen(false);
                                setIsDateRange(true);
                                setValue(dateRangeStringVal);
                            }}
                        >
                            {/* // ? This is not an Icon to maintain consistency */}
                            <div className="w-3">
                                {value === dateRangeStringVal || !selectedDuration?.label
                                    ? '✓'
                                    : ''}
                            </div>
                            <Title order={5} className="flex items-center justify-center">
                                Choose a {isMonthSelector ? 'month' : 'date'} range{' '}
                                <CaretRight size={16} className="mt-0.5" />
                            </Title>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

export default DatePicker;
export { formateDateRangeValueForDisplay };

import { LatamQueryFilters } from '../hooks/useLatamQueryFilters';
import { ProductMetric } from '../services/productMetrics/productMetricsList';
import { ProductMetricsQueryTable } from '../services/productMetrics/productMetricsQueryTable';
import { TableViewBy, TableViewByLabels } from '../types/components/pricingIntelligence';
import { dateSubtraction, dateSubtractionMonthly } from '../utils/dateHelpers';
import { colorScales } from './colors';

const LATAM_VALUE_METRIC = {
    price: 'AVERAGE PRICE (R$)',
    percentDiscount: 'Percent DISCOUNT (%)',
    mostFrequentPrice: 'Most Frequent Price (R$)',
};

const latamTimeseriesMetric: Record<string, Partial<ProductMetric>> = {
    price: {
        label: 'Price',
        series: ['Average Price', 'Max Price', 'Min Price', 'Price', 'Most Frequent Price'],
        unit: 'R$',
    },
    // mostFrequentPrice: {
    //     label: 'Most Frequent Price',
    //     series: ['Most Frequent Price'],
    //     unit: 'R$',
    // },
    percentDiscount: {
        label: 'Percent Discount',
        series: ['Percent Discount'],
        unit: '%',
    },
};
const EX_DATA: ProductMetricsQueryTable['productMetricsQueryTableRes']['products'][''][0] = {
    metric: 'price',
    value: 3.6874582560296902,
    store: {
        address: '3-3300 Kuhio Hwy',
        geocoded: false,
        id: 'w_2308',
        is_store: true,
        latitude: 38.528957,
        longitude: -98.148111,
        name: 'WALMART',
        state: 'HI',
        city: '',
        zipcode: '96766',
        geo_name: '',
    },
    most_recent_date: null,
    product_name: '',
};

const stateLabels = {
    US: 'United States',
    AZ: 'Arizona',
    AL: 'Alabama',
    AK: 'Alaska',
    AR: 'Arkansas',
    CA: 'California',
    'CA (ALL)': 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DC: 'District of Columbia',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};
const metricList = ['oos', 'on_promo', 'price', 'sales', 'pct_discount'];

const latamLocationFilterOptionsKeysToParamKeys: Record<keyof LatamQueryFilters, string> = {
    city: 'cities',
    state: 'states',
    zip_code: 'zip_codes',
    operation: 'operations',
    is_b2b: 'is_b2b',
    data_source: 'data_source',
    out_of_stock: 'Out of Stock',
};

const viewByOptions: {
    value: TableViewBy;
    label: TableViewByLabels;
}[] = [
    { value: 'country', label: 'country' },
    { value: 'state', label: 'state' },
    { value: 'city', label: 'city' },
    { value: 'operation', label: 'operation' },
    { value: 'zip_code', label: 'CEP' },
];
const viewByValuesToLabels: Record<TableViewBy, TableViewByLabels> = {
    city: 'city',
    country: 'country',
    state: 'state',
    operation: 'operation',
    zip_code: 'CEP',
};
const viewByLabelsToValues: Record<TableViewByLabels, TableViewBy> = {
    city: 'city',
    country: 'country',
    state: 'state',
    operation: 'operation',
    CEP: 'zip_code',
};

const durations = [
    // ? Yesterday + 6 days = Last 7 days
    { value: dateSubtraction(6, 'days'), label: 'Last 7 Days' },
    { value: dateSubtraction(1, 'M'), label: 'Last 30 Days' },
    { value: dateSubtraction(3, 'M'), label: 'Last 3 Months' },
    { value: dateSubtraction(6, 'M'), label: 'Last 6 Months' },
    { value: dateSubtraction(12, 'M'), label: 'Last 12 Months' },
];

export const monthlyDurations = [
    { value: dateSubtractionMonthly(1, 'M'), label: 'Last Month' },
    { value: dateSubtractionMonthly(3, 'M'), label: 'Last 3 Months' },
    { value: dateSubtractionMonthly(6, 'M'), label: 'Last 6 Months' },
    { value: dateSubtractionMonthly(12, 'M'), label: 'Last 12 Months' },
];

const BEES_YELLOW = colorScales.yellow[0];

export {
    stateLabels,
    metricList,
    EX_DATA,
    latamTimeseriesMetric,
    LATAM_VALUE_METRIC,
    latamLocationFilterOptionsKeysToParamKeys,
    viewByOptions,
    viewByValuesToLabels,
    viewByLabelsToValues,
    BEES_YELLOW,
    durations,
};
